import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    let defStateVal = $('#user_billing_state').val()
    var person_type = $('#user_customer_type').val();

    $('#user_customer_type').on('change', function(){
      if (person_type != $(this).val()) $('#user_person_type').val('')
      
      var isCompany = ($(this).val() == 'customer_type_compania');
      $('#user_id_card').closest('.form-group').toggle(!isCompany)
      $('#user_document_type').closest('.form-group').toggle(!isCompany)
      $('#user_rif_number').closest('.form-group').toggle(isCompany)
      
      $('option[value=person_type_residente]').toggle(!isCompany)
      $('option[value=person_type_no_residente]').toggle(!isCompany)
      $('option[value=person_type_juridica_domiciliada]').toggle(isCompany)
      $('option[value=person_type_juridica_no_domiciliada]').toggle(isCompany)
      
    }).trigger('change')

    $('#user_billing_country').on('change', function(){
      var id = $(this).val()
      if (id == "") {
        $('option[data-country-id]').hide()
        return
      }
      
      var opt = $(this).find('option[value='+id+']')[0]
      var odooId = $(opt).data('odoo-id')
      $('option[value=""]').html('- Selecciona')
      $('option[data-country-id]').hide()
      $('option[data-country-id="'+odooId+'"]').show()
    }).trigger('change')

  }
}
