import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
const log = console.log

export default class extends Controller {
  
  disconnect() {
    $('.plan-item a[data-behaviour=select]').off('click')
  }
  
  connect() {
    $('.plan-item a[data-behaviour=select]').on('click', function(e){
      e.preventDefault()
      $('.plan-item').removeClass('selected')
      
      var pp = $(this).closest('.plan-item')
      var input = pp.find('[data-behaviour="select-input"]') 
      input.click()
      
      $('.plan-item').each(function(){
        var input = $(this).find('[data-behaviour="select-input"]') 
        if (input.is(':checked')) $(this).addClass('selected')
      })
    })
  }
}
