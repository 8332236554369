import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  
  disconnect() {
  }
  
  connect() {
    const self = this
    
    $(self).find("#search_order").autocomplete({
      source: "/api/orders/autocomplete",
      minLength: 2,
      select: function( event, ui ) {
        // console.log( "Selected: " + ui.item.value + " aka " + ui.item.id );
        $(self).find('#pago_movil_webhook_payable_id').val(ui.item.id)
        $(self).find('#pago_movil_webhook_payable_type').val('Order')
      }
    });

    $(self).find("#search_invoice").autocomplete({
      source: "/api/invoices/autocomplete",
      minLength: 2,
      select: function( event, ui ) {
        // console.log( "Selected: " + ui.item.value + " aka " + ui.item.id );
        $(self).find('#pago_movil_webhook_payable_id').val(ui.item.id)
        $(self).find('#pago_movil_webhook_payable_type').val('Invoice')
      }
    });
  }
}
