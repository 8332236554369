import { Controller } from "@hotwired/stimulus"

const log = console.log
// import {debounce} from "lodash"

export default class extends Controller {
  
  disconnect() {
  }
  
  connect() {
    const self = this
    
    const origValue = this.element.value
    let i
    let fun
    
    switch (this.element.tagName) {
    case 'SELECT':
    case 'INPUT':
      this.element.addEventListener('change', function(){
        if (self.value == this.value) return
        this.form.requestSubmit()
      })
    default:
      fun = function() {
        clearTimeout(i)
        if (this.value == origValue) return
        let f = this.form
        i = setTimeout(function(){
          f.requestSubmit()
        }, 500)
      }
      this.element.addEventListener('keyup', fun)
      this.element.addEventListener('change', fun)
    } 
    
  }
}
