import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  
  disconnect() {
    this.modal.hide()
    this.modal.dispose()
  }
  
  connect() {
    let self = this
    self.modal = new Modal(self.element, {})
    self.modal.show()
  }
}
