import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    $('#pago_movil_form_date').flatpickr({
      dateFormat: 'd/m/Y',
      locale: 'es',
      maxDate: 'today',
    })

    var int
    function startTimer(duration, display, cb) {
      var timer = duration, minutes, seconds;
      int = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
          timer = duration;
          clearInterval(int)
          cb()
        }
      }, 1000);
    }

    window.onload = function () {
      var seconds = parseInt($('#countdown').data('seconds')),
      display = document.querySelector('#countdown');
      startTimer(seconds, display, function(){
        // console.log('done!')
        window.location.reload()
      });
    };
  }
}
