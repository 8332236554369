import { Controller } from "@hotwired/stimulus"
import {Toast} from "bootstrap"

export default class extends Controller {
  connect() {
    let tt = new Toast(this.element, {
      delay: 8000
    })
    tt.show()
  }
}
