import "@hotwired/turbo-rails"
import "@hotwired/stimulus"

import { application } from "../controllers/application"
import { registerControllers } from 'stimulus-vite-helpers'

const controllers = import.meta.globEager('../controllers/**/*_controller.js')
registerControllers(application, controllers)

import * as bootstrap from "bootstrap"
import "@oddcamp/cocoon-vanilla-js"
const log = console.log

function init() {
    
}

// document.addEventListener('turbo:before-fetch-request', function(){
//   console.log('turbo:before-fetch-request')
// })

document.addEventListener('turbo:before-cache', function(){
  // log('before-cache')
})

document.addEventListener('turbo:load', function(){
  // console.log('turbo:load')
  init()
})

document.addEventListener('turbo:frame-load', function(e){
  // console.log('turbo:frame-load')
  // init()
})

document.addEventListener('turbo:submit-end', function(e){
  // console.log('turbo:submit-end')
  setTimeout(()=>{ init() }, 100)
})

// document.addEventListener('turbo:submit-start', function(e){
//   console.log('turbo:submit-start')
//   log(e)
// })

document.addEventListener('turbo:frame-render', function(e){
  // console.log('turbo:frame-render')
  // init()
})

document.addEventListener('turbo:render', function(){
  // console.log('turbo:render')
  init()
})
