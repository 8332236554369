import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  
  disconnect() {
    $(this.element).find('#user_role').off('change')
  }
  
  connect() {
    $(this.element).find('#user_role').on('change',function(){
      $('.altice-fields').toggle(($(this).val() == 'altice_customer'))
    }).change()
  }
}
