import { Controller } from "@hotwired/stimulus"
// import $ from "jquery"
const log = console.log
import VanillaTilt from 'vanilla-tilt'

// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
// import 'swiper/swiper-bundle.min.css';

export default class extends Controller {
  
  disconnect() {
  }
  
  connect() {
    const self = this
    
    // Init Bootstrap Tooltips
    // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    // Init VanillaTilt Plugin
    // VanillaTilt.init(document.querySelectorAll('.js-tilt'), {
    //   reverse: true,
    //   max: 15,
    //   speed: 1500,
    // })

    const authSwiper = new Swiper('.auth__swiper', {
      slidesPerView: 1,
      parallax: true,
      speed: 3500,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: '.auth__swiper--pagination',
        clickable: true,
      },
    });

  }
}


